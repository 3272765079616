.about__section {
  background-color: var(--first-color-lighter);
}

.about__container {
  gap: 2rem;
  max-width: var(--big-screen-width);
  margin: 4rem auto;
}

.about__img {
  margin: 0 auto;
}

.about__subtitle {
  background: var(--first-color);
  padding: 0.5rem 1rem;
  margin-bottom: 0.8rem;
  color: var(--default-bw);
}

.about__description {
  color: var(--title-color);
}

.about__img {
  box-shadow: var(--bs);
  max-height: 300px;
}

@media (min-width: 600px) {
  .about__container {
    grid-template-columns: 1fr 200px;
    grid-template-areas:
      'title image'
      'subtitle image'
      'description image';
    grid-column-gap: 2rem;
  }

  .about__title {
    grid-area: title;
    max-height: max-content;
  }

  .about__subtitle {
    grid-row: 2;
    grid-column: 1 / -1;
    position: relative;
    left: -1rem;
    width: calc(100% + 2rem); /* Stick out 2rem on both sides */
    padding-left: 1rem; /* Content stays in sync */
    padding-right: calc(200px + 3rem);
    /* So that text doesn't cross image, grid gap and 2rem extra width */
    display: flex;
    align-items: center;
    max-height: calc(max-content + 1rem);
  }

  .about__img {
    grid-area: image;
    z-index: 1;
  }
}
