.underConstructionFlex {
  width: 100%;
  display: flex;
  justify-content: center;
}

.underConstructionContainer {
  grid-template-columns: max-content 1fr max-content;
  gap: 1rem;
}

.underConstructionIcon {
  align-self: center;
}

.underConstructionText {
  text-align: center;
}
