.modal__overlay {
  position: fixed;
  z-index: 1300;
  inset: 0;
}

.modal__backdrop {
  position: fixed;
  inset: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.modal__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content {
  --modal-content-padding-top: 3.5rem;
  --modal-content-padding-side: var(--mb-1-5);
  --modal-content-padding-bottom: var(--mb-2);
  margin: 4rem;
  min-width: calc(100% - 4rem);
  max-height: calc(100% - 8rem);
  border: 2px solid var(--text-color-light);
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background-color: var(--body-color);
  color: var(--text-color);
  padding: var(--modal-content-padding-top) var(--modal-content-padding-side)
    var(--modal-content-padding-bottom);
  position: relative; /* For Modal Close */
  overflow-y: auto;
}

.modal__content_header {
  position: absolute;
  height: 2.5rem;
  margin: calc(var(--modal-content-padding-top) * -1)
    calc(var(--modal-content-padding-side) * -1);
  border-bottom: 1px solid var(--text-color-light);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.modal__close_button {
  background-color: var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 4px;
  padding: 2px;
}

.modal__close_button:hover {
  background-color: var(--button-hover-color);
}

.modal__close {
  color: var(--error-color);
}

@media screen and (min-width: 500px) {
  .modal__content {
    min-width: 300px;
    max-width: 600px;
  }
}
