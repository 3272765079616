.nav__link {
  color: var(--inverted-bw);
}

.nav__link:hover {
  color: var(--nav-items-hover-color);
}

.nav__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--h4-font-size);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
}

.nav__menu_hidden {
  display: none;
}

.nav__menu_bigscreen_wrapper {
  display: none;
}

.nav__item_selected {
  background-color: var(--nav-items-select-color);
}

@media screen and (max-width: 350px) {
  .nav__item {
    padding: var(--mb-1) var(--mb-1);
  }
}

@media screen and (min-width: 351px) {
  .nav__item {
    padding: var(--mb-1) var(--mb-1-5);
  }
}

@media screen and (max-width: 600px) {
  .nav__list {
    flex-direction: column;
  }

  .nav__mobile_menu_wrapper {
    position: fixed;
    top: var(--header-height);
    z-index: 2000;
  }

  .nav__mobile_menu {
    width: 100vw;
    height: calc(100vh - var(--header-height));
    position: absolute;
    overflow-y: auto;
    background-color: var(--body-color);
  }

  .nav__icon {
    margin-right: 1rem;
  }

  .nav__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 4rem;
  }
}

@media screen and (min-width: 600px) {
  .nav__mobile_menu_wrapper {
    display: none;
  }

  .nav__menu_bigscreen_wrapper {
    display: block;
    margin-left: auto;
  }

  .nav__link {
    color: var(--nav-items-color);
    font-weight: var(--font-medium);
    font-size: var(--h4-font-size);
  }

  .nav__list {
    padding: 0 var(--mb-1);
    flex-direction: row;
    column-gap: 2rem;
  }

  .nav__icon {
    display: none;
  }

  .nav__item {
    cursor: pointer;
    padding: 0.4rem 1rem;
  }

  .nav__item_selected {
    border-radius: 0.3rem;
  }

  .nav__item_selected > .nav__link {
    color: var(--inverted-bw);
  }
}
