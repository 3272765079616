.footer__crypto {
  flex: 0 0 auto;
  margin: 0;
}

.footer__crypto_label {
  margin-bottom: 1rem;
  color: var(--title-color);
}

.footer__crypto_address {
  background-color: var(--first-color-lighter);
  color: var(--inverted-bw);
  display: grid;
  grid-template-columns: 1fr 4rem;
  grid-template-areas: 'address copy';
  grid-column-gap: 2rem;
  max-height: max-content;
  letter-spacing: 1px;
  padding: 1rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: var(--font-medium);
}

.footer__crypto_address_text {
  grid-row: 1;
  grid-column: 1 / -1;
  word-break: break-all;
  padding-right: 2.3rem;
}

.footer__crypto_icon {
  grid-area: copy;
  justify-self: right;
}

.footer__crypto_copy_icon {
  grid-area: copy;
  justify-self: right;
}

@media screen and (min-width: 768px) {
  .footer__crypto_copy_icon {
    cursor: pointer;
  }

  .footer__crypto_copied_icon {
    cursor: no-drop;
  }
}
