.snackbar__container {
  background-color: var(--body-color);
  color: var(--text-color);
  z-index: 1400;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 8rem;
  min-height: 2rem;
  min-width: 8rem;
  max-width: 20rem;
  word-wrap: break;
  left: 50%;
  right: auto;
  bottom: 3rem;
  transform: translateX(-50%);
  display: block;
  word-wrap: break-word;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.snackbar__content {
  padding: 1rem 1rem;
  text-align: center;
  color: var(--title-color);
}
